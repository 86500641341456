.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.leftSection {
  display: flex;
  flex-direction: column;
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
}

.image {
  width: 45%;
  min-height: 400px;
  background-color: #cceae8;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
  }
}
