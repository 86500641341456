@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--colorFail);
}

.aside {
  box-shadow: none;
}

.videoContaier {
  display: flex;
  flex-direction: column;
  position: relative;
}

.video {
  width: 100%;
  height: 100%;
}

.asideContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 48px 0;
  }
}

.mobileHeading {
  flex-shrink: 0;
  margin: 60px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
    padding: 6px 0 2px 0;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 0 24px 0;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  border-top: 1px solid var(--colorGrey100);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  color: var(--colorGrey700);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    margin-top: 56px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--colorGrey100);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }

    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  margin: 24px 0 26px 0;
}

.mobileReviews {
  border-top: 1px solid var(--colorGrey100);
  margin-bottom: 36px;
}

.desktopReviews {
  border-top: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.desktopReviewsWrapper {
  max-width: 600px;
  margin-top: 28px;
}

.desktopReviewsTitle {
  composes: textLarge from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.giveawaySlide {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  padding: 40px;
}

.giveawaySlideTitle {}

.giveawayDescription {}

.giveawayInfo {
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  margin: 0 40px 20px 40px;
}

.dummyBox {
  height: 200px;
  width: 200px;
  --random-hue: calc(360 * random());
  background-color: #cceae8;
  margin-right: 40px;
}

.sectionWrapper {
  margin-bottom: 40px;
}

.sectionTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.continueButton {
  max-width: 300px;
}

.wrapper {
  width: 100%;
  max-width: 99vw;
  overflow: hidden;
}

.listingCard {
  width: 100%;
  height: 100%;
}