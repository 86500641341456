.sliderContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.sliderContent {
  display: flex;
  flex-direction: row;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.slide {
  flex: 0 0 100%;
  height: 100%;
  scroll-snap-align: start;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
}

.icon {
  transform: scale(3);
  color: white;
  background-color: rgb(0, 0, 0, 0.6);
  border-radius: 5px;

  &:hover {
    transform: scale(2.8);
  }
}

.prevButton {
  left: 15px;
}

.nextButton {
  right: 15px;
}