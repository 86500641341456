@import '../../styles/customMediaQueries.css';

.root {
    padding: 80px;
}


.listingCards {
    padding: 0 24px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    width: 100%;
}

.listingCard {
    width: 100%;
}