.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin-bottom: 0;
  @media (max-width: 768px) {
    text-align: center;
  }
}

.subtitle {
  margin-top: 0;
  font-size: 22px;
  @media (max-width: 768px) {
    text-align: center;
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.infoSquareTitle {
  font-size: 22px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    text-align: center;
  }
}

.infoSquare {
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.infoSquareDescription {
  margin-top: 0;
  @media (max-width: 768px) {
    text-align: center;
  }
}
