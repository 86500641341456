@import '../../../../styles/customMediaQueries.css';
@import '../../../../styles/marketplaceDefaults.css';

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.continueButton {
  composes: buttonDefault from global;
  margin-top: 20px;
  &:hover {
    text-decoration: none;
  }
}

.modalTitle {
  width: 100%;
  text-align: center;
  line-height: 40px;
}
