.root {
  display: flex;
  flex-direction: row;
}

.allWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.closeIcon {
  margin-left: 44px;

  &:hover {
    cursor: pointer;
  }
}

.wrapper {
  background-color: white;
  box-shadow: var(--boxShadow);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
  flex-wrap: wrap;

  @media (max-width: 820px) {
    display: none;
  }
}

.item {
  white-space: nowrap;
  margin: 0 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 50px;
  scroll-snap-align: center;

  &:hover {
    transform: scale(1.03);
  }
}

.itemAll {
  white-space: nowrap;
  margin: 0 20px;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: start;
  cursor: pointer;
  width: 240px;
  min-height: 50px;
  /* scroll-snap-align: center; */

  &:hover {
    transform: scale(1.03);
  }
}

.itemAllWrapper {
  /* width: 240px; */
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  width: 240px;
  flex-wrap: nowrap;
}

.icon {
  margin-right: 5px;
  height: 2px;
  font-size: small;
}

.allCategoriesWrapper {
  position: absolute;
  left: 0;
  width: 320px;
  background-color: white;
  box-shadow: var(--boxShadow);
  bottom: 0;
  transform: translateY(100%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  padding: 20px;
}

.allSubcategoriesWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  left: 0;
  top: -20px;
  right: 0;
  /* max-width: 500px; */
  min-width: 50vw;
  max-height: 70wh;
  margin-left: 0px;
  background-color: white;
  box-shadow: var(--boxShadow);
  bottom: 0;
}

.subcategoriesWrapper {
  position: absolute;
  width: 100%;
  background-color: white;
  box-shadow: var(--boxShadow);
  bottom: 0;

  transform: translateY(98%);
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.subcategoryItem {
  margin-right: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--marketplaceColor);
  min-width: 18%;

  &:hover {
    transform: scale(1.03);
  }
}